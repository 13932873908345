/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/browser';
import LogRocket from 'logrocket';

type User = {
  id: string;
  fullName: string;
  email: string;
  roleName: string;
};

class ErrorReporting {
  static useLogRocket: boolean;

  static init(): void {
    this.useLogRocket = window.localStorage.getItem('USE_LOGROCKET') === 'true';

    if (__DEV__ || !process.env.SENTRY_DSN) {
      return;
    }

    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.ENVIRONMENT,
      release: process.env.BUILD_VERSION,
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          // allows the view of content static texts, won't allow input text.
          maskAllText: false,
        }),
      ],
      sampleRate: 1.0,
      tracesSampleRate: 1.0,
      // 10% rate is advised for production, increase if necessary
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        /^Network request failed with status 401 - "UNAUTHORIZED"$/,
        "ObservableQuery with this id doesn't exist",
        'Network request failed with status 200',
        'Schema must be an instance of GraphQLSchema',
        /(Method webWidget:on.open does not exist.)/,
      ],
    });

    window.addEventListener('unhandledrejection', error => {
      this.captureError(error.reason, {}, 'UnhandledPromiseRejection');
    });
  }

  static setUserContext(user: User): void {
    if (__DEV__) {
      return;
    }

    if (this.useLogRocket) {
      LogRocket.init('takumi/takumi-web');

      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }

    Sentry.setUser({
      id: user.id,
      username: user.fullName,
      email: user.email,
    });
    Sentry.configureScope((scope): void => {
      scope.setExtra('Role Name', user.roleName);
    });

    if (this.useLogRocket) {
      LogRocket.identify(user.id, {
        name: user.fullName,
        email: user.email,
        roleName: user.roleName,
      });
    }
  }

  static captureError(
    error: Error,
    extra: Record<string, string> = {},
    logger = 'ErrorReporting'
  ): void {
    if (__DEV__) {
      console.error('ErrorReporting.captureError');
      console.error(error);
      return;
    }

    if (!(error instanceof Error)) {
      return;
    }

    Sentry.withScope((scope): void => {
      scope.setTag('logger', logger);
      Object.entries(extra).forEach(([key, value]) => scope.setTag(key, value));
      Sentry.captureException(error);
    });
  }

  static captureMessage(
    message: string,
    extra: Record<string, string> = {},
    logger = 'ErrorReporting'
  ): void {
    if (__DEV__) {
      console.error('ErrorReporting.captureMessage', message);
      return;
    }

    Sentry.withScope((scope): void => {
      scope.setTag('logger', logger);
      Object.entries(extra).forEach(([key, value]) => scope.setTag(key, value));
      Sentry.captureMessage(message);
    });
  }
}

export default ErrorReporting;
