import {Divider, Grid, Typography} from '@material-ui/core';
import React from 'react';

import PlatformWithLabel from 'components/Widgets/PlatformWithLabel/PlatformWithLabel';

import {InfluencerPerformanceByPlatformResponse} from 'hooks/useDataServiceApi/types';

interface Props {
  data?: InfluencerPerformanceByPlatformResponse;
}

const formatPercent = (percentage: number) => {
  return (percentage * 100).toFixed(2) + '%';
};

const GigEngagement: React.FC<Props> = ({data}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">Gig Engagement</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">Rate by account organic followers</Typography>
        {data?.map(
          (platform, index) =>
            platform?.engagement_rate_by_followers_avg && (
              <PlatformWithLabel
                key={index}
                platform={platform?.social_media_platform}
                label={formatPercent(platform?.engagement_rate_by_followers_avg)}
              />
            )
        )}
      </Grid>
      <Grid item>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item>
        <Typography variant="body2">Rate by impressions</Typography>
        {data?.map(
          (platform, index) =>
            platform?.engagement_rate_by_impressions_avg !== undefined && (
              <PlatformWithLabel
                key={index}
                platform={platform?.social_media_platform}
                label={formatPercent(platform?.engagement_rate_by_impressions_avg)}
              />
            )
        )}
      </Grid>
    </Grid>
  );
};

export default GigEngagement;
