import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import * as qs from 'query-string';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {isEqual} from 'lodash';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import useFeatureFlags from 'hooks/useFeatureFlags';

import {RowWrap, InfluencerFilter, SearchInput, LoaderDots} from '../../../../components/Widgets';
import {SelfTagModal} from '../../../../components/SelfTags';
import {numberWithCommas} from '../../../../consts/utilities';
import InfluencerCard from './components/InfluencerCard/InfluencerCard';
import {InfluencerSearchQuery, RegionQuery} from './queries';
import SelectRegion, {ALL_SUPPORTED_REGIONS} from '../../../../components/Region/SelectRegion';
import {InfluencerFilterType} from '../../../../components/Widgets/InfluencerFilter/helper';
import {INFLUENCER_FILTER_STATE} from '../../../../consts/variables';
import {withHookPagination} from '../../../../services/graphql';
import InfiniteScroller from '../../../../components/Widgets/InfiniteScroller/InfiniteScroller';
import {parseAccountStateForQuery, qsParseOptions, qsStringifyOptions} from './helper';
import {black40} from '../../../../consts/brand.integration';
import CreateInfluencerProfile from './components/CreateInfluencerProfile';
import AlternativeInfluencerSearch from './components/AlternativeInfluencerSearch';
import {InfluencerInfluenscoreData} from 'hooks/useDataServiceApi/types';
import useCurrentUser from 'hooks/useCurrentUser';
import {useDataServiceApi} from 'hooks/useDataServiceApi';
import {useDebounce} from 'use-debounce';
import ErrorReporting from 'services/error-reporting';

type InfluencerWithInfluenscoreData = Influencer & {influenscoreData: InfluencerInfluenscoreData};

const AdminInfluencers: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);
  const currentUser = useCurrentUser();

  const {useCreateInfluencerProfile, useAlternativeInfluencerSearch, showInfluencerInfluenscore} =
    useFeatureFlags();
  const [influencerResults, setInfluencerResults] = useState<InfluencerWithInfluenscoreData[]>([]);

  const [accountState, setAccountState] = useState<string>('reviewed');
  const [filters, setFilters] = useState<InfluencerFilterType>();
  const [selfTags, setSelfTags] = useState<Record<string, any>>({tagIds: []});
  const [region, setRegion] = useState<string>(ALL_SUPPORTED_REGIONS);

  const [searchString, setSearchString] = useState<string>('');

  const [debouncedSearchString] = useDebounce(searchString, 1000);

  const {loading: regionLoading, data: regionData} = useQuery(RegionQuery, {
    variables: {
      includeUnsupported: true,
    },
  });

  const [fetchInfluencers, {loading: fetchInfluencersLoading, data, fetchMore, variables}] =
    useLazyQuery(InfluencerSearchQuery, {
      variables: {
        regionId: region,
        searchString: debouncedSearchString,
        eligible: accountState === INFLUENCER_FILTER_STATE.New.value,
        state: parseAccountStateForQuery(accountState),
        sortOrder: 'desc',
        ...filters,
        information: selfTags,
      },
      notifyOnNetworkStatusChange: true,
    });

  const {
    error: dataApiError,
    loading: dataApiLoading,
    fetchInfluenscoreInfluencerV1Beta,
  } = useDataServiceApi();

  const influencerSearchLoading = useMemo(() => {
    return fetchInfluencersLoading || dataApiLoading;
  }, [fetchInfluencersLoading, dataApiLoading]);

  const loadMore = withHookPagination(
    InfluencerSearchQuery,
    'influencerSearch',
    data,
    variables,
    fetchMore
  );

  const updateURI = () =>
    history.replace({
      ...location,
      search: qs.stringify(
        {
          state: accountState,
          region,
          filters: qs.stringify(filters, qsStringifyOptions),
          selfTags: qs.stringify(selfTags, qsStringifyOptions),
          q: debouncedSearchString,
        },
        qsStringifyOptions
      ),
    });

  const fetchInfluencersInfluenscoreData = async (
    influencerIds: string[]
  ): Promise<InfluencerInfluenscoreData[]> => {
    const response = await fetchInfluenscoreInfluencerV1Beta({
      apiToken: currentUser?.takumiDataApiToken ?? '',
      influencerIds: influencerIds.join(','),
    });
    return response
      ? response.map(data => ({
          influencerId: data.influencer_id,
          cpf: data.s_cpf ?? 0,
          cpe: data.s_cpe ?? 0,
          cpr: data.s_cpr ?? 0,
          cpm: data.s_cpm ?? 0,
          overall: data.influenscore ?? 0,
        }))
      : [];
  };

  // Cache score data so that we don't fetch the same data multiple times
  const influencerSearchCache = useRef<Influencer[]>([]);
  const influenscoreCache = useRef<Map<string, InfluencerInfluenscoreData>>(new Map());

  useEffect(() => {
    const mergeResults = (
      fetchedInfluencers: Influencer[],
      fetchedInfluenscoreData: InfluencerInfluenscoreData[]
    ) => {
      // Using a lookup map first - happens only once
      // Merge the new influenScoreData with the existing cached lookup Map
      influencerSearchCache.current = fetchedInfluencers;
      for (let j = 0; j < fetchedInfluenscoreData.length; j++) {
        const data = fetchedInfluenscoreData[j];
        if (data.influencerId) {
          influenscoreCache.current.set(data.influencerId, data);
        }
      }
      // fetchedInfluenscoreData.forEach(data => {
      //   if (data.influencerId) {
      //     influenscoreCache.current.set(data.influencerId, data);
      //   }
      // });

      // Now lookups are O(1) instead of O(n)
      const influencersWithInfluenscores: InfluencerWithInfluenscoreData[] = [];
      for (let i = 0; i < fetchedInfluencers.length; i++) {
        const influencer = fetchedInfluencers[i];
        const influenscoreData = influenscoreCache.current.get(influencer.id);
        influencersWithInfluenscores.push({...influencer, influenscoreData: influenscoreData!});
      }

      return influencersWithInfluenscores;
    };

    if (data && data.influencerSearch.count > 0) {
      if (showInfluencerInfluenscore) {
        let startTime = new Date().getTime();

        // Filter out influencers that we already requested influenScoreData for
        const newInfluencerIds = data.influencerSearch.edges.reduce((acc: string[], edge: any) => {
          if (!influencerSearchCache.current.find(d => d.id === edge.node.id)) {
            acc.push(edge.node.id);
          }
          return acc;
        }, []);

        console.log('Fetching influenscore data for', newInfluencerIds.length, 'influencers');
        const influenscoreData = fetchInfluencersInfluenscoreData(newInfluencerIds);
        influenscoreData
          .then(influenscoreDataResponse => {
            console.log(
              'Fetched influenscore data in ' + (new Date().getTime() - startTime) + 'ms,',
              influenscoreDataResponse.length,
              'influenscores'
            );
            startTime = new Date().getTime();
            const mergedResults = mergeResults(
              data.influencerSearch.edges.map((edge: any) => edge.node),
              influenscoreDataResponse
            );
            // influencers with influenScoreData
            console.log('Merged results in ' + (new Date().getTime() - startTime) + 'ms');
            setInfluencerResults(mergedResults as InfluencerWithInfluenscoreData[]);
          })
          .catch(error => {
            ErrorReporting.captureError(error);
            setInfluencerResults(data.influencerSearch.edges.map((edge: any) => edge.node));
          });
      } else {
        setInfluencerResults(data.influencerSearch.edges.map((edge: any) => edge.node));
      }
    } else {
      setInfluencerResults([]);
    }
  }, [data, showInfluencerInfluenscore]);

  useEffect(() => {
    if (params.region && params.region !== region) {
      setRegion(params.region as string);
    }

    if (params.filter && params.filter !== accountState) {
      setAccountState(params.filter as string);
    }

    if (params.filters) {
      const parsed = qs.parse(params.filters as string, qsParseOptions);

      // fixes case where single element is not parsed as array by qs.parse
      if (parsed.interestIds && typeof parsed.interestIds === 'string') {
        parsed.interestIds = [parsed.interestIds];
      }

      if (!isEqual(parsed, filters)) {
        setFilters(parsed);
      }
    }

    if (params.selfTags) {
      const parsed = qs.parse(params.selfTags as string, qsParseOptions);
      if (!isEqual(parsed, selfTags)) {
        setSelfTags(parsed);
      }
    }

    return () => {
      influenscoreCache.current.clear();
      influencerSearchCache.current = [];
    };
  }, []);

  useEffect(() => {
    fetchInfluencers();
    updateURI();
  }, [accountState, region, filters, selfTags, debouncedSearchString]);

  return (
    <Grid container style={{marginTop: 70}} direction="column" spacing={4}>
      <Grid item container direction="column" spacing={2}>
        <Grid item container spacing={4}>
          <Grid item xs={12} md={8}>
            <SearchInput
              searchString={searchString}
              placeholder="Search Influencers"
              onChange={event => setSearchString(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectRegion
              countries={regionData?.countries || []}
              pending={regionLoading}
              region={regionLoading ? '' : region}
              showUnsupported
              onChange={setRegion}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <ToggleButtonGroup
            value={accountState}
            exclusive
            onChange={(event, value) => setAccountState(value)}
          >
            {[
              INFLUENCER_FILTER_STATE.All,
              INFLUENCER_FILTER_STATE.Disabled,
              INFLUENCER_FILTER_STATE.New,
              INFLUENCER_FILTER_STATE.Reviewed,
              INFLUENCER_FILTER_STATE.Verified,
            ].map(item => (
              <ToggleButton key={item.name} value={item.value} aria-label={item.name}>
                <Typography variant="button">{item.name}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs>
          <InfluencerFilter selected={filters} onChange={setFilters} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {useCreateInfluencerProfile && (
            <Grid item xs={6}>
              <CreateInfluencerProfile />
            </Grid>
          )}
          <Grid item xs={6}>
            <SelfTagModal right value={selfTags} onChange={setSelfTags} />
          </Grid>
        </Grid>
        {!influencerSearchLoading && influencerResults.length === 0 && debouncedSearchString && (
          <>
            <Grid item xs>
              <Typography variant="body1" align="center">
                No influencers found when searching for "{`${debouncedSearchString}`}"
              </Typography>
            </Grid>
            {useAlternativeInfluencerSearch && (
              <Grid item xs>
                <Typography variant="body1" align="center">
                  Was this influencer created just recently?
                  <p>Try this option instead...</p>
                  <br />
                  <p>
                    <AlternativeInfluencerSearch />
                  </p>
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        {influencerSearchLoading && <LoaderDots />}
        {influencerResults.length > 0 && (
          <>
            <div className={classes.influencerCount}>
              <Typography variant="body2">
                {`${numberWithCommas(data?.influencerSearch?.count)} influencer${
                  data?.influencerSearch?.count > 1 ? 's' : ''
                }`}
              </Typography>
            </div>
            <InfiniteScroller
              loading={influencerSearchLoading}
              loadMore={loadMore}
              hasMore={data?.influencerSearch?.pageInfo.hasNextPage}
            >
              <RowWrap>
                {influencerResults.map((influencer: InfluencerWithInfluenscoreData) => (
                  <Link
                    key={influencer.id}
                    to={`/influencers/${influencer.id}/${
                      influencer.isSignedUp ? 'gigs' : 'settings'
                    }`}
                  >
                    <InfluencerCard influencer={influencer} />
                  </Link>
                ))}
              </RowWrap>
            </InfiniteScroller>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  influencerCount: {
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
    color: black40,
    marginBottom: '30px',
  },
});

export default AdminInfluencers;
